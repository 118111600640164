import _ from 'lodash'
import React from 'react'
import { withSnackbar } from 'notistack'
import queryString from 'query-string'

import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { Card, CardContent, Grid } from '@material-ui/core'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import { IconWrapper } from '../components/icon'
import Campaign from '../backend/campaign'
import CampaignDetails from '../components/campaign/campaign_details'
import CampaignTitle from '../components/campaign/campaign_title'
import Chip from '../components/chip'
import Colors from '../utils/colors'
import ImageGallery from '../components/campaign/image_gallery'
import Page from './page'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    width: 'inherit',
    maxWidth: '530px',
  },
  imagePanel: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    maxWidth: 'min-content',
    margin: theme.spacing(1.5),
    '& p': {
      marginTop: theme.spacing(1),
      color: Colors.get('grey'),
    },
  },
  images: {
    display: 'flex',
    alignSelf: 'center',
    flexGrow: 1,
    maxWidth: '500px',
    maxHeight: '500px',
    [theme.breakpoints.down('sm')]: {
      width: '95vw',
      height: '50vh',
    },
    [theme.breakpoints.up('sm')]: {
      width: '40em',
      height: '40em',
    },
  },
  impacts: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  details: {
    flex: 1,
    justifyContent: 'end',
    flexWrap: 'nowrap',
    padding: theme.spacing(0),
  },
  mainpanel: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spacer: {
    marginBottom: theme.spacing(5),
  },
  tags: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  tag: {
    margin: theme.spacing(1),
  },
  timer: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    width: 'inherit',
    maxWidth: '530px',
  },
  video: {
    margin: theme.spacing(1),
    width: '100%',
    maxWidth: '700px',
  },
  supportButton: {
    margin: theme.spacing(1),
  },
  divider: {
    maxWidth: '1000px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mobilevid: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  desktopvid: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}))

function CampaignPage({ location }) {
  const classes = useStyles()

  const [goal, setGoal] = React.useState(0)
  const [images, setImages] = React.useState([])
  const [pricing, setPricing] = React.useState(null)
  const [progress, setProgress] = React.useState(0)
  const [raised, setRaised] = React.useState(0)
  const [sold, setSold] = React.useState(0)
  const [tags, setTags] = React.useState([])
  const [title, setTitle] = React.useState(null)

  React.useEffect(() => {
    const URL_ID = _.toInteger(_.get(queryString.parse(location.search), 'id'))
    Campaign.get(URL_ID).then(campaign => {
      if (!campaign) return

      setGoal(campaign.goal)
      setImages(campaign.images)
      setPricing(campaign.pricing)
      setRaised(campaign.raised)
      setSold(campaign.sold)
      setTags(_.sortBy(campaign.tags, o => -o.rank))
      setTitle(campaign.title)

      if (goal !== null) {
        setProgress(Math.round((campaign.sold / campaign.goal) * 100))
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function content() {
    return (
      <Grid container className={classes.container}>
        <Grid>
          {title ? (
            <CampaignTitle title={title} pricing={pricing} />
          ) : (
            <Skeleton variant="text" className={classes.spacer} />
          )}
          {/** MAIN CONTENT */}
          <Grid className={classes.mainpanel}>
            <Grid container className={classes.imagePanel}>
              {images.length ? (
                <ImageGallery images={images} className={classes.images} />
              ) : (
                <Skeleton variant="rect" width={210} height={200} />
              )}
            </Grid>
            <Card className={classes.card} variant="outlined">
              <CardContent>
                <Grid>
                  <CampaignDetails
                    className={classes.details}
                    sold={sold}
                    goal={goal}
                    raised={raised}
                    progress={progress}
                  />
                </Grid>
                <Grid className={classes.tags}>
                  {_.map(tags, (tag, idx) => {
                    if (tag.rank === 0) return null
                    const name = _.get(tag.icon, 'name')
                    const library = _.get(tag.icon, 'library')
                    const icon = name ? new IconWrapper(name, library) : null
                    const color = _.get(tag, 'color')
                    return (
                      <Chip
                        key={idx}
                        className={classes.tag}
                        color={color}
                        label={tag.name}
                        icon={icon}
                      />
                    )
                  })}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Page
      content={content()}
      title={title ? `${title} | Made to Uplift` : null}
      showCart={false}
    />
  )
}

export default withSnackbar(CampaignPage)
